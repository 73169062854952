@font-face {
	font-family: "MierB03";
	src: url("../../../../../o/f/400/MierB03-SubsetCyr-Regular.woff2") format("woff2"), url("../../../../../o/f/400/MierB03-SubsetCyr-Regular.woff") format("woff");
	font-style: normal;
	font-weight: 400;
	font-display: swap; // Fallback text is immediately rendered in the next available system typeface
}
@font-face {
	font-family: "MierB03";
	src: url("../../../../../o/f/700/MierB03-SubsetCyr-Bold.woff2") format("woff2"), url("../../../../../o/f/700/MierB03-SubsetCyr-Bold.woff") format("woff");
	font-style: normal;
	font-weight: 700;
	font-display: swap; // Fallback text is immediately rendered in the next available system typeface
}
@font-face {
	font-family: "MierB03";
	src: url("../../../../../o/f/800/MierB03-SubsetCyr-ExtraBold.woff2") format("woff2"), url("../../../../../o/f/800/MierB03-SubsetCyr-ExtraBold.woff") format("woff");
	font-style: normal;
	font-weight: 750;
	font-display: swap; // Fallback text is immediately rendered in the next available system typeface
}

body {
	font-family: "MierB03", "Helvetica", "Arial", sans-serif;
}
